import PropTypes from 'prop-types';
import React, { useEffect, useState, useRef } from 'react';
import Head from 'next/head';
import { ViewProductKlaviyo } from 'helpers/klaviyo';
import styles from './styles-rts';
import ImageGallery from './ImageGallery/index-rts';
import ImagesGalleryMobile from './ImagesGalleryMobile/index-rts';
import ProductDescriptions from './ProductDescriptions/index-rts';
import ProductPerformanceTips from './ProductToolTip/ProductTips';
import Progressbar from 'components/LineBar/progressBar';
import ProductSpecification from './ProductSpecification/ProductSpecification';
import ProductFeatures from './ProductFeatures/index-rts';
import ImageViewer from './ImageViewer/index-rts';
import VideoViewer from './ImageViewer/video-rts';
import BreadcrumbMenu from './BreadcrumbMenu';
import ProductCustomize from './ProductCustomize';
import ProductAddToCart from './ProductDescriptions/ProductAddToCart';
import ProductAddCartMobile from './ProductDescriptions/ProductAddCartMobile';
import Image from 'next/image';
import { isMobile } from 'react-device-detect';
import { useRouter } from 'next/router';
import FPSCounter from '../../components/FPSCounter';
import DropdownOption from '../../components/FPSCounter/DropdownOption';
import { db } from '../../utils/firebase';
import {
  collection,
  query,
  where,
  getDocs,
  collectionGroup,
} from 'firebase/firestore';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import { fbEvent } from '@rivercode/facebook-conversion-api-nextjs';
import Variants from './Variants';
import AddToBagRTS from './AddToBag/rts';
import useScreenWidth from 'utils/useScreenWidth';
import TiktokPixel from 'tiktok-pixel';
import PerformanceChart from './PerformanceChart';

import {
  sendShopifyAnalytics,
  getClientBrowserParameters,
  AnalyticsEventName,
} from '@shopify/hydrogen-react';
import NewProductCustomize from './NewProductCustomize';
import _ from 'lodash';
import FPSBarCounter from 'components/FPSBarCounter';
import { v4 as uuidv4 } from 'uuid';
import Performance from './Performance';
import VariantsMobile from './VariantsMobile';
import PerformanceMobile from './PerformanceMobile';
import NewProductSpecification from './ProductSpecification/NewProductSpecification';

const Product = ({ data, metaObjects, setupMetaObjects }) => {
  const { header, footer } = global || {};
  const { product } = data;
  const [FPS, setFPS] = useState([
    { name: '', FPS1080: 0, FPS1440: 0, FPS4K: 0 },
  ]);
  const [activeOptionImageDesktop, setActiveOptionImageDesktop] = useState('');
  const [activeOptionImageMobile, setActiveOptionImageMobile] = useState('');
  const [progress1080P, setProgress1080P] = useState(0);
  const [progress1440P, setProgress1440P] = useState(0);
  const [progress4KP, setProgress4KP] = useState(0);
  const [progress1080PMobile, setProgress1080PMobile] = useState(0);
  const [progress1440PMobile, setProgress1440PMobile] = useState(0);
  const [progress4KPMobile, setProgress4KPMobile] = useState(0);
  const [activeTab, setActiveTab] = useState('specifications');

  const router = useRouter();
  const sendPageView = (analyticsPageData) => {
    const payload = {
      ...getClientBrowserParameters(),
      ...analyticsPageData,
    };
    sendShopifyAnalytics({
      eventName: AnalyticsEventName.PAGE_VIEW,
      payload,
    });
  };

  const analyticsShopData = {
    shopId: 'gid://shopify/Shop/63704072436',
    currency: 'SGD',
    acceptedLanguage: 'en',
  };

  const hasUserConsent = true;

  const analytics = {
    hasUserConsent,
    ...analyticsShopData,
  };

  useEffect(() => {
    const productAvailable =
      product?.variants?.edges[0]?.node?.quantityAvailable > 0
        ? true
        : product?.variants?.edges[0]?.node?.quantityAvailable <= 0 &&
          product?.variants?.edges[0]?.node?.currentlyNotInStock
        ? true
        : false;
    router.pathname == '/products/[...handle]' &&
    product?.variants?.edges[0]?.node?.quantityAvailable > 0
      ? document && document.body.classList.add('product-page-chat')
      : product?.variants?.edges[0]?.node?.quantityAvailable <= 0 &&
        product?.variants?.edges[0]?.node?.currentlyNotInStock
      ? document && document.body.classList.add('product-page-chat')
      : document && document.body.classList.add('product-page-notify');
  });

  useEffect(() => {
    const eventId = uuidv4();

    fbEvent({
      eventName: 'ViewContent',
      eventId: eventId,
      products: [
        {
          sku: product.variants.edges[0].node.sku,
          quantity: 1,
        },
      ],
      value: product.variants.edges[0].node.price.amount,
      currency: 'SGD',
      enableStandardPixel: false,
    });
    fetchFPS();

    const options = {
      debug: false,
    };

    TiktokPixel.init(process.env.NEXT_PUBLIC_TIKTOK_PIXEL_ID, options);
    TiktokPixel.track('ViewContent', {
      content_category: 'product',
      content_id: productId.replace('gid://shopify/Product/', ''),
      content_name: productTitle,
      content_type: 'product',
      currency: 'SGD',
      value: productPrice,
      event_id: eventId,
    });

    sendPageView(analytics);
  }, [product]);

  const fetchFPS = async () => {
    let sortData;
    if (product.tags.some((obj) => obj.startsWith('rtsfps:'))) {
      try {
        const rtsfpsId = product.tags
          .filter((item) => item.startsWith('rtsfps:'))[0]
          .split(':')[1];
        const q = query(collection(db, 'rts-fps-performance'));
        const querySnapshot = await getDocs(q);
        const filteredDocs = querySnapshot.docs.filter(
          (doc) => doc.id === rtsfpsId,
        );
        filteredDocs.forEach((doc) => {
          sortData = doc
            .data()
            .FPS.sort((a, b) =>
              (a.name?.toLowerCase() || a.Name.toLowerCase()) >
              (b.name?.toLowerCase() || b.Name?.toLowerCase())
                ? 1
                : -1,
            );
          let index;
          if (
            sortData.filter((e) => e.name === 'Warzone 2').length > 0 ||
            sortData.filter((e) => e.Name === 'Warzone 2').length > 0
          ) {
            index = sortData.findIndex(
              (x) => x.Name === 'Warzone 2' || x.name === 'Warzone 2',
            );
            setProgress1080P(sortData[index].FPS1080);
            setProgress1440P(sortData[index].FPS1440);
            setProgress4KP(sortData[index].FPS4K);
            setProgress1080PMobile(sortData[index].FPS1080);
            setProgress1440PMobile(sortData[index].FPS1440);
            setProgress4KPMobile(sortData[index].FPS4K);
          } else {
            setProgress1080P(sortData[0].FPS1080);
            setProgress1440P(sortData[0].FPS1440);
            setProgress4KP(sortData[0].FPS4K);
            setProgress1080PMobile(sortData[0].FPS1080);
            setProgress1440PMobile(sortData[0].FPS1440);
            setProgress4KPMobile(sortData[0].FPS4K);
          }
          setFPS(sortData);
        });

        let q2;
        if (sortData[0].name) {
          if (sortData.filter((e) => e.name === 'Warzone 2').length > 0) {
            q2 = query(
              collection(db, 'rts-games-db'),
              where('name', '==', 'Warzone 2'),
            );
          } else {
            q2 = query(
              collection(db, 'rts-games-db'),
              where('name', '==', sortData[0].name),
            );
          }
        } else {
          if (sortData.filter((e) => e.Name === 'Warzone 2').length > 0) {
            q2 = query(
              collection(db, 'rts-games-db'),
              where('name', '==', 'Warzone 2'),
            );
          } else {
            q2 = query(
              collection(db, 'rts-games-db'),
              where('name', '==', sortData[0].Name),
            );
          }
        }
        const querySnapshot2 = await getDocs(q2);
        querySnapshot2.forEach((doc) => {
          setActiveOptionImageDesktop(doc.data().img);
          setActiveOptionImageMobile(doc.data().img);
        });
      } catch (err) {
        console.log('error :::::::::::: ', err);
      }
    }
  };

  const [finalProductPrice, setFinalProductPrice] = useState(
    data?.product?.variants?.edges[0]?.node?.price,
  );
  const [finalComparedProductPrice, setFinalComparedProductPrice] = useState(
    data?.product?.variants.edges[0].node.compareAtPrice,
  );
  const [addToCart, setAddToCart] = useState([data?.product]);
  const [showModal, setShowModal] = useState(false);
  const [imageIndex, setImageIndex] = useState(0);
  const [activeSpecification, setActiveSpecification] = useState(1);
  const [preInstallPrice, setPreInstallPrice] = useState({});

  const [selectedUpsellProduct, setSelectedUpsellProduct] = useState([]);

  let metaFiledCheck = [];

  const [upsellProductPrice, setUpsellProductPrice] = useState({});
  const [loading, setLoading] = useState(false);
  const [check, setCheck] = useState(false);
  const [tooltip, setTooltip] = useState(-1);
  const [isTooltipOpen, setIsTooltipOpen] = useState(false);
  const [videoPreviewLink, setVideoPreview] = useState(null);
  const productTitle = product.title;
  const productId = product.id;
  const productHandle = product.handle;
  const productPrice = product.variants.edges[0].node.price;
  const productAltText = product.images?.edges[0]?.node?.altText;
  const productSKU = product.variants.edges[0].node.sku;
  const productImage = product.images?.edges[0]?.node?.originalSrc;
  const imagesGallery = product.images.edges;
  const productVariant = product.variants.edges[0].node;
  const productTags = product.tags;
  const upsellFieldsFilter =
    product?.metafields?.edges.length > 0 &&
    product?.metafields?.edges.filter(
      (el) => el.node.namespace === 'upsell' || el.node.namespace === 'UPSELL',
    );
  const videoFieldsFilter =
    product?.metafields?.edges.length > 0 &&
    product?.metafields?.edges.filter(
      (el) => el.node.namespace.toLowerCase() === 'video',
    );

  const customFieldsFilter =
    product?.metafields?.edges.length > 0 &&
    product?.metafields?.edges.filter(
      (el) => el.node.namespace.toLowerCase() === 'custom',
    );

  const otherMetaFieldsFilter =
    product?.metafields?.edges.length > 0 &&
    product?.metafields?.edges.filter(
      (el) =>
        el.node.namespace !== 'upsell' &&
        el.node.namespace !== 'UPSELL' &&
        el.node.namespace.toLowerCase() !== 'video' &&
        el.node.namespace.toLowerCase() !== 'custom',
    );
  const upsellProductMetaFields =
    typeof upsellFieldsFilter[0]?.node?.value !== 'undefined' &&
    upsellFieldsFilter
      ? JSON.parse(upsellFieldsFilter[0]?.node?.value)
      : null;
  const productAvailable =
    product?.variants?.edges[0]?.node?.quantityAvailable > 0
      ? true
      : product?.variants?.edges[0]?.node?.quantityAvailable <= 0 &&
        product?.variants?.edges[0]?.node?.currentlyNotInStock
      ? true
      : false;
  const productCompareAtPrice = product.variants.edges[0].node.compareAtPrice;
  const productDescription = (
    <div
      // eslint-disable-next-line react/no-danger
      dangerouslySetInnerHTML={{ __html: data.product.descriptionHtml }}
    />
  );
  const description = data.product.description;

  const shortDescription = customFieldsFilter
    ? customFieldsFilter.find((item) => item.node.key === 'short_description')
        ?.node?.value ?? ''
    : '';
  const specificationsTags = productTags.filter((item) =>
    item.startsWith('specifications:'),
  );
  const sameDayShipping = productTags.filter((item) =>
    item.startsWith('shipping:'),
  );

  const freightTag = productTags.filter((item) => item.startsWith('freight:'));

  const skuTag = productTags.filter((item) => item.startsWith('sku'));

  let sku = '';
  if (skuTag.length > 0) {
    sku = data.product.variants.edges[0].node.sku;
  }

  const freight = freightTag.length > 0 && freightTag[0].split(':')[1];

  const sameDayShipAvab =
    sameDayShipping.length > 0 && sameDayShipping[0].split(':')[1];

  const shippingDescription =
    sameDayShipping.length > 0 && sameDayShipping[0].split(':')[2];

  const getShippingTag = (tag) => {
    switch (tag) {
      case 'same-day-shipping':
        if (shippingDescription != undefined) {
          return (
            <p>
              {'Same Day Delivery'}
              <br />
              {`(${shippingDescription})`}
            </p>
          );
        } else {
          return <p>{'Same Day Delivery'}</p>;
        }
      case 'next-day-shipping':
        if (shippingDescription != undefined) {
          return (
            <p>
              {'Next Day Shipping'}
              <br />
              {`(${shippingDescription})`}
            </p>
          );
        } else {
          return <p>{'Next Day Shipping'}</p>;
        }
      case 'pre-order':
        if (shippingDescription != undefined) {
          return (
            <p>
              {'Pre Order'}
              <br />
              {`(${shippingDescription})`}
            </p>
          );
        } else {
          return <p>{'Pre Order'}</p>;
        }
      default:
        return '';
    }
  };

  const performanceTags = productTags?.filter((item) =>
    item.startsWith('performance:'),
  );
  const warrantyTag = productTags?.filter((item) =>
    item.startsWith('warranty:'),
  );
  const handleSpecificationActive = (activeSpecification) => {
    setActiveSpecification(activeSpecification);
  };

  const finalPrice = (val1, val2, type = null) => {
    if (type === 'minus') {
      const finalPrice = (parseFloat(val1) - parseFloat(val2)).toFixed(2);
      return finalPrice;
    } else {
      const finalPrice = (parseFloat(val1) + parseFloat(val2)).toFixed(2);
      return finalPrice;
    }
  };
  const handlePreInstallResetChange = async (type) => {
    setLoading(true);

    let data = preInstallPrice;

    let cart = addToCart.filter((el) => el?.id !== data[type].id);
    setAddToCart(cart);
    let productPrice = await finalPrice(
      finalProductPrice,
      data[type].price,
      'minus',
    );
    setFinalProductPrice(productPrice);
    let finalComparedPrice = await finalPrice(
      finalComparedProductPrice,
      data[type].comparedPrice,
      'minus',
    );
    setFinalComparedProductPrice(finalComparedPrice);

    data[type].id = 'none';
    data[type].price = 0.0;
    data[type].comparedPrice = 0.0;
    setPreInstallPrice(data);
    setLoading(false);
  };
  const handlePreSelected = async (type, product) => {
    setLoading(true);
    const product_id = product && product?.id,
      price =
        product?.variants?.edges[0]?.node?.price !== null
          ? product?.variants?.edges[0]?.node?.price
          : 0.0,
      comparedPrice =
        product?.variants?.edges[0]?.node?.compareAtPrice !== null
          ? product?.variants?.edges[0]?.node?.compareAtPrice
          : 0.0;
    let productPrice = await finalPrice(finalProductPrice, price);

    setFinalProductPrice(productPrice);
    let finalComparedPrice = await finalPrice(
      finalComparedProductPrice,
      comparedPrice,
    );
    setFinalComparedProductPrice(finalComparedPrice);
    let cart = addToCart.filter((el) => el?.id !== preInstallPrice[type]?.id);
    setAddToCart([...cart, product]);
    preInstallPrice[type].id = product_id;
    preInstallPrice[type].price = price;
    preInstallPrice[type].comparedPrice = comparedPrice;
    setPreInstallPrice(preInstallPrice);
    setLoading(false);
  };
  const handlePreInstallChange = async (type, product) => {
    setLoading(true);

    const product_id = product && product?.id,
      price = product?.variants?.edges[0]?.node?.price?.amount
        ? setupMetaObjects.length > 0
          ? +product?.variants?.edges[0]?.node?.price.amount
          : +product?.variants?.edges[0]?.node?.price.amount
        : 0.0,
      comparedPrice =
        product?.variants?.edges[0]?.node?.compareAtPrice !== null
          ? setupMetaObjects.length > 0
            ? +product?.variants?.edges[0]?.node?.compareAtPrice.amount
            : +product?.variants?.edges[0]?.node?.compareAtPrice
          : 0.0;

    if (product_id === preInstallPrice[type]?.id) {
      let productPrice = await finalPrice(finalProductPrice, price, 'minus');

      setFinalProductPrice(productPrice);
      let finalComparedPrice = await finalPrice(
        finalComparedProductPrice,
        comparedPrice,
        'minus',
      );
      setFinalComparedProductPrice(finalComparedPrice);

      let cart = addToCart.filter((el) => el?.id !== product_id);
      setAddToCart(cart);

      preInstallPrice[type].id = '';
      preInstallPrice[type].price = 0.0;
      preInstallPrice[type].comparedPrice = 0.0;
      setPreInstallPrice(preInstallPrice);
      setLoading(false);
    } else {
      let oldPrice =
        parseFloat(finalProductPrice) -
        parseFloat(preInstallPrice[type]?.price);
      let productPrice = await finalPrice(oldPrice, price);
      setFinalProductPrice(productPrice);

      let oldComparedPrice =
        parseFloat(finalComparedProductPrice) -
        parseFloat(preInstallPrice[type]?.comparedPrice);
      let finalComparedPrice = await finalPrice(
        oldComparedPrice,
        comparedPrice,
      );
      setFinalComparedProductPrice(finalComparedPrice);

      let cart = addToCart.filter((el) => el?.id !== preInstallPrice[type]?.id);
      if (price === 0) {
        setAddToCart([...cart]);
      } else {
        setAddToCart([...cart, product]);
      }

      preInstallPrice[type].id = product_id;
      preInstallPrice[type].price = price;
      preInstallPrice[type].comparedPrice = comparedPrice;
      setPreInstallPrice(preInstallPrice);
      setLoading(false);
    }
  };
  const handleUpsellChange = async (type, product) => {
    const product_id = product && product?.id,
      price =
        product?.variants?.edges[0]?.node?.price !== null
          ? product?.variants?.edges[0]?.node?.price
          : 0.0,
      comparedPrice =
        product?.variants?.edges[0]?.node?.compareAtPrice !== null
          ? product?.variants?.edges[0]?.node?.compareAtPrice
          : product?.variants?.edges[0]?.node?.price;
    if (
      upsellProductPrice &&
      typeof upsellProductPrice[type] !== 'undefined' &&
      product_id == upsellProductPrice[type]?.id
    ) {
      let productPrice = await finalPrice(finalProductPrice, price, 'minus');
      setFinalProductPrice(productPrice);
      //compared price
      let finalComparedPrice = await finalPrice(
        finalComparedProductPrice,
        comparedPrice,
        'minus',
      );
      setFinalComparedProductPrice(finalComparedPrice);
      // end
      setUpsellProductPrice({
        ...upsellProductPrice,
        [type]: {
          id: '',
          price: 0.0,
          comparedPrice: 0.0,
        },
      });
      const cart = addToCart.filter((el) => el?.id !== product_id);
      setAddToCart(cart);
    } else {
      let oldPrice =
        parseFloat(finalProductPrice) -
        parseFloat(
          typeof upsellProductPrice[type] !== 'undefined'
            ? upsellProductPrice[type]?.price
            : 0.0,
        );
      let productPrice = await finalPrice(oldPrice, price);
      setFinalProductPrice(productPrice);
      //compared price
      let oldComparedPrice =
        parseFloat(finalComparedProductPrice) -
        parseFloat(
          typeof upsellProductPrice[type] !== 'undefined'
            ? upsellProductPrice[type]?.comparedPrice
            : 0.0,
        );
      let finalComparedPrice = await finalPrice(
        oldComparedPrice,
        comparedPrice,
      );
      setFinalComparedProductPrice(finalComparedPrice);
      // end
      setUpsellProductPrice({
        ...upsellProductPrice,
        [type]: {
          id: product_id,
          price: price,
          comparedPrice: comparedPrice,
        },
      });
      if (
        upsellProductPrice &&
        typeof upsellProductPrice[type] !== 'undefined'
      ) {
        const cart = addToCart.filter(
          (el) => el?.id !== upsellProductPrice[type].id,
        );
        setAddToCart([...cart, product]);
      } else {
        const cart = addToCart;
        setAddToCart([...cart, product]);
      }
    }
  };
  const handleCloseModal = () => {
    setIsTooltipOpen(false);
    setVideoPreview(null);
  };
  const handleVideoPreview = (videoLink) => {
    setVideoPreview(videoLink);
    if (isMobile) setShowModal(true);
  };
  const handleModalClose = (val) => {
    setShowModal(val);
    setVideoPreview(null);
  };

  const [customDesignUrl, setCustomDesignUrl] = useState('');

  const handleCustomDesignUrl = (value, namespace, product) => {
    setCustomDesignUrl(value);
  };

  const handleMetaFiled = (name) => {
    metaFiledCheck = [...metaFiledCheck, name];
    setCheck(metaFiledCheck.length);
  };
  const otherMetaFieldsFilterNewOrder = [
    otherMetaFieldsFilter.find((item) => item.node.namespace === 'warranty'),
    ...otherMetaFieldsFilter.filter(
      (item) => item.node.namespace !== 'warranty',
    ),
  ];

  const variantMetaFields = [
    otherMetaFieldsFilter.find((item) => item.node.namespace === 'variants'),
  ];

  const rankingMetaField = [
    customFieldsFilter.find(
      (item) => item.node.namespace === 'custom' && item.node.key === 'ranking',
    ),
  ];

  const checkUsername = (item) => item?.node.namespace === 'hdd';
  if (otherMetaFieldsFilterNewOrder.some(checkUsername)) {
    const tempHddMetafield = otherMetaFieldsFilterNewOrder[1];
    const tempRamUpgradteMetafield = otherMetaFieldsFilterNewOrder[3];

    otherMetaFieldsFilterNewOrder[1] = otherMetaFieldsFilterNewOrder[2];
    otherMetaFieldsFilterNewOrder[3] = tempHddMetafield;
    otherMetaFieldsFilterNewOrder[2] = tempRamUpgradteMetafield;
  }

  useEffect(() => {
    router.beforePopState(({ as }) => {
      if (as !== router.asPath) {
        sessionStorage.setItem('productHandle', productHandle);
      }
      return true;
    });

    return () => {
      router.beforePopState(() => true);
    };
  }, [router]);

  const deliveryDaysTag = productTags.filter((item) =>
    item.startsWith('delivery:'),
  );
  const deliveryDays =
    deliveryDaysTag.length > 0 && deliveryDaysTag[0].split(':')[1];

  const collectionDaysTag = productTags.filter((item) =>
    item.startsWith('collection:'),
  );
  const collectionDays =
    collectionDaysTag.length > 0 && collectionDaysTag[0].split(':')[1];

  const widthSize = useScreenWidth();

  const handleSelectingUpsellProducts = (product, category) => {
    setSelectedUpsellProduct((prevState) => {
      const exists = prevState.some((item) => item.id === product.id);
      if (exists) {
        return prevState.filter((item) => item.id !== product.id);
      } else {
        const updatedState = prevState.filter(
          (item) => item.category !== category,
        );
        return [...updatedState, product];
      }
    });
  };

  const updatePreInstallPrice = (array) => {
    const updatedPreInstallPrice = {};

    array.forEach((item) => {
      const title = item?.title;
      const snakeCaseTitle = _.snakeCase(title);
      updatedPreInstallPrice[snakeCaseTitle] = {
        price: 0.0,
        id: '',
        comparedPrice: 0.0,
      };
    });

    return updatedPreInstallPrice;
  };

  useEffect(() => {
    if (setupMetaObjects.length > 0 || metaObjects.length > 0) {
      const combinedMetaObjects = [...setupMetaObjects, ...metaObjects];
      setPreInstallPrice(updatePreInstallPrice(combinedMetaObjects));
    } else if (metaObjects.length > 0) {
      setPreInstallPrice(updatePreInstallPrice(metaObjects));
    } else {
      setPreInstallPrice({
        pre_installed: {
          price: 0.0,
          id: '',
          comparedPrice: 0.0,
        },
        ram_upgrades: {
          price: 0.0,
          id: '',
          comparedPrice: 0.0,
        },
        ssd: {
          price: 0.0,
          id: 'none',
          comparedPrice: 0.0,
        },
        hdd: {
          price: 0.0,
          id: 'none',
          comparedPrice: 0.0,
        },
        warranty: {
          price: 0.0,
          id: '',
          comparedPrice: 0.0,
        },
        power_supply_upgrade: {
          price: 0.0,
          id: '',
          comparedPrice: 0.0,
        },
        cpu_cooling_upgrade: {
          price: 0.0,
          id: '',
          comparedPrice: 0.0,
        },
        panel_print: {
          price: 0.0,
          id: '',
          comparedPrice: 0.0,
        },
      });
    }
  }, []);

  const calledBanner = productTags.filter((item) =>
    item.trim().startsWith('banner:'),
  );

  const calledBannerText =
    calledBanner.length > 0 && calledBanner[0].split(':')[1];

  return (
    <>
      <div style={{ backgroundColor: '#1A1A1A' }}>
        {global?.window?._learnq
          ? ViewProductKlaviyo(
              productTitle,
              productId,
              productImage,
              productPrice,
              productCompareAtPrice,
              productHandle,
              productSKU,
            )
          : ''}

        <div className='product_section wrapper'>
          <BreadcrumbMenu
            productHandle={productHandle}
            productTitle={productTitle}
            productTags={productTags}
          />
          {videoPreviewLink == null && showModal ? (
            <ImageViewer
              imagesGallery={imagesGallery}
              handleModalClose={handleModalClose}
              imageIndex={imageIndex}
              handleImageIndex={setImageIndex}
            />
          ) : null}

          <div className='product_information'>
            <div
              className='product_images_section'
              style={{
                height: activeOptionImageDesktop != '' ? '1200px' : '1200px',
              }}
            >
              <ImageGallery
                productImage={productImage}
                imagesGallery={imagesGallery}
                productTitle={productTitle}
                showModal={showModal}
                handleModalOpen={setShowModal}
                handleImageIndex={setImageIndex}
                videoPreviewLink={videoPreviewLink}
                handleVideoPreview={handleVideoPreview}
                videoUrl={
                  videoFieldsFilter.length > 0 &&
                  videoFieldsFilter[0].node.value
                }
                productAltText={productAltText}
                rankingMetaField={rankingMetaField}
              />
              {/* Commented until will needed */}
              {/* <Performance /> */}
              {activeOptionImageDesktop != '' && (
                <div className='fps-bar-container-desktop'>
                  <div className='fps-bar-section'>
                    <div>
                      <h1 className='fps-bar-title'>GAMING PERFORMANCE</h1>
                    </div>

                    <div className='rts-performance-sub-container'>
                      {activeOptionImageDesktop == '' ? (
                        <Skeleton
                          width={155}
                          height={'100%'}
                          baseColor='#101010'
                          highlightColor='#1c1c1c'
                        />
                      ) : (
                        <div className='boxart-container'>
                          <img
                            draggable='false'
                            className='rts-performance-boxart'
                            src={activeOptionImageDesktop}
                            alt='boxart'
                          />
                        </div>
                      )}

                      <div className='rts-performance-fps-main-container'>
                        <div className='rts-performance-dropdown'>
                          {FPS[0].FPS1080 == 0 ? (
                            <Skeleton
                              width={'95%'}
                              height={45}
                              baseColor='#101010'
                              highlightColor='#1c1c1c'
                            />
                          ) : (
                            <DropdownOption
                              options={FPS}
                              setActiveOptionImage={setActiveOptionImageDesktop}
                              setProgress1080P={setProgress1080P}
                              setProgress1440P={setProgress1440P}
                              setProgress4KP={setProgress4KP}
                            />
                          )}
                        </div>

                        <div className='fps-bar-counter-container '>
                          <div className='fps-bar-counter-box'>
                            <p className='fps-bar-counter-title'>1080P</p>

                            <FPSBarCounter
                              id={'1080P'}
                              progress={progress1080P}
                              game={activeOptionImageDesktop}
                              maxValue={progress1080P}
                            />
                          </div>

                          <div className='fps-bar-counter-box'>
                            <p className='fps-bar-counter-title'>1440P</p>
                            <FPSBarCounter
                              id={'1440P'}
                              progress={progress1440P}
                              game={activeOptionImageDesktop}
                              maxValue={progress1080P}
                            />
                          </div>

                          <div className='fps-bar-counter-box'>
                            <p className='fps-bar-counter-title'>4K</p>
                            <FPSBarCounter
                              id={'4K'}
                              progress={progress4KP}
                              game={activeOptionImageDesktop}
                              maxValue={progress1080P}
                            />
                          </div>
                        </div>
                      </div>
                    </div>

                    <div>
                      <p className='fps-bar-description'>
                        General graphical quality setting recommendation for
                        games by general category. Results may vary.
                      </p>
                    </div>
                  </div>
                </div>
              )}
            </div>
            <div className='images_gallery_mobile'>
              <ImagesGalleryMobile
                imagesGallery={imagesGallery}
                showModal={showModal}
                handleModalOpen={setShowModal}
                handleImageIndex={setImageIndex}
                videoPreviewLink={videoPreviewLink}
                handleModalClose={handleModalClose}
                handleVideoPreview={handleVideoPreview}
                videoUrl={
                  videoFieldsFilter.length > 0 &&
                  videoFieldsFilter[0].node.value
                }
              />
            </div>

            <div className='product_contents'>
              <div className='title-section'>
                <ProductDescriptions
                  products={addToCart}
                  sameDayShipping={sameDayShipping}
                  productTitle={productTitle}
                  productPrice={productPrice}
                  productVariant={productVariant}
                  productDescription={description}
                  productShortDescription={shortDescription}
                  productCompareAtPrice={productCompareAtPrice}
                  productTags={productTags}
                  productImage={productImage}
                  productHandle={productHandle}
                  productSKU={productSKU}
                  productAvailable={productAvailable}
                  productId={productId}
                  handleVideoPreview={handleVideoPreview}
                  videoUrl={
                    videoFieldsFilter.length > 0 &&
                    videoFieldsFilter[0].node.value
                  }
                  variantMetaFields={variantMetaFields}
                />

                {/* {sameDayShipAvab && sameDayShipAvab != 'delayed' && (
                  <a
                    className='shipping-container-desktop'
                    href='#same-day-delivery-badge'
                  >
                    {sameDayShipAvab == 'same-day-shipping' && (
                      <Image
                        draggable={false}
                        src={`https://cdn.shopify.com/s/files/1/0637/0407/2436/files/same-day-shipping-icon.png?v=1714066707`}
                        alt='product_features'
                        width={80}
                        height={80}
                      />
                    )}
                    {getShippingTag(sameDayShipAvab)}
                  </a>
                )} */}
              </div>

              <div className='tab-section'>
                <div
                  className={`select-tab ${
                    activeTab == 'specifications' && 'active-tab'
                  }`}
                  onClick={() => setActiveTab('specifications')}
                >
                  SPECIFICATIONS
                </div>
                <div
                  className={`select-tab ${
                    activeTab == 'description' && 'active-tab'
                  }`}
                  onClick={() => setActiveTab('description')}
                >
                  DESCRIPTION
                </div>
              </div>

              {activeTab === 'specifications' ? (
                <ProductFeatures productTags={productTags} />
              ) : (
                <div className='description-wrapper'>
                  <div className='short_description'>{description}</div>

                  <div className='description-read-more'>
                    <a href='#product-details-section'>Read More</a>
                  </div>
                </div>
              )}

              <div
                className='add_to_cart_desc_button'
                style={{ marginBottom: 20, marginTop: 10 }}
              >
                <AddToBagRTS
                  products={addToCart}
                  soldOut={!productAvailable}
                  disabled={!productAvailable}
                  productTitle={productTitle}
                  selectedVariant={productVariant}
                  productId={productId}
                  customizedClass='add_to_cart'
                  isOnDescription
                  footer={false}
                />
              </div>

              {variantMetaFields[0] != undefined && (
                <>
                  <Variants
                    calledBannerText={calledBannerText}
                    variantMetaFields={variantMetaFields}
                    productHandle={productHandle}
                  />
                  <VariantsMobile
                    calledBannerText={calledBannerText}
                    variantMetaFields={variantMetaFields}
                    productHandle={productHandle}
                  />
                </>
              )}

              {activeOptionImageMobile != '' && (
                <div className='fps-bar-container-mobile'>
                  <div className='fps-bar-section'>
                    <div>
                      <h1 className='fps-bar-title'>GAMING PERFORMANCE</h1>
                    </div>

                    <div className='rts-performance-sub-container'>
                      {activeOptionImageMobile == '' ? (
                        <Skeleton
                          width={155}
                          height={'100%'}
                          baseColor='#101010'
                          highlightColor='#1c1c1c'
                        />
                      ) : (
                        <div className='boxart-container'>
                          <img
                            draggable='false'
                            className='rts-performance-boxart'
                            src={activeOptionImageMobile}
                            alt='boxart'
                          />
                        </div>
                      )}

                      <div className='rts-performance-fps-main-container'>
                        <div className='rts-performance-dropdown'>
                          {FPS[0].FPS1080 == 0 ? (
                            <Skeleton
                              width={'95%'}
                              height={45}
                              baseColor='#101010'
                              highlightColor='#1c1c1c'
                            />
                          ) : (
                            <DropdownOption
                              options={FPS}
                              setActiveOptionImage={setActiveOptionImageMobile}
                              setProgress1080P={setProgress1080P}
                              setProgress1440P={setProgress1440P}
                              setProgress4KP={setProgress4KP}
                            />
                          )}
                        </div>

                        <div className='fps-bar-counter-container'>
                          <div className='fps-bar-counter-box'>
                            <p className='fps-bar-counter-title'>1080P</p>

                            <FPSBarCounter
                              id={'1080P'}
                              progress={progress1080P}
                              game={activeOptionImageMobile}
                              maxValue={progress1080P}
                            />
                          </div>

                          <div className='fps-bar-counter-box'>
                            <p className='fps-bar-counter-title'>1440P</p>
                            <FPSBarCounter
                              id={'1440P'}
                              progress={progress1440P}
                              game={activeOptionImageMobile}
                              maxValue={progress1080P}
                            />
                          </div>

                          <div className='fps-bar-counter-box'>
                            <p className='fps-bar-counter-title'>4K</p>
                            <FPSBarCounter
                              id={'4K'}
                              progress={progress4KP}
                              game={activeOptionImageMobile}
                              maxValue={progress1080P}
                            />
                          </div>
                        </div>
                      </div>
                    </div>

                    <div>
                      <p className='fps-bar-description'>
                        General graphical quality setting recommendation for
                        games by general category. Results may vary.
                      </p>
                    </div>
                  </div>
                </div>
              )}

              {/* Commented until will needed */}
              {/* <PerformanceMobile /> */}

              {performanceTags.length > 0 && (
                <div className='performance-tags'>
                  <h2 className='h2'>PERFORMANCE</h2>
                  {performanceTags.map((tag, idx) => {
                    const title =
                      typeof tag.split(':')[1] !== 'undefined'
                        ? tag.split(':')[1].replace('-', ' ').toUpperCase()
                        : '';
                    const percentage =
                      typeof tag.split(':')[2] !== 'undefined'
                        ? tag.split(':')[2]
                        : 0;
                    const handleOpenModal = (idx) => {
                      setIsTooltipOpen(true);
                      setTooltip(idx);
                    };
                    return (
                      <div className='stat' key={tag}>
                        <div className='stat-label'>
                          <div className='performance-title'>
                            <div>{title}</div>
                          </div>
                          <div className='performance-tips'>
                            <div
                              className='tip_icon'
                              onClick={() => handleOpenModal(idx)}
                            >
                              <Image
                                draggable={false}
                                src='https://cdn.shopify.com/s/files/1/0637/0407/2436/files/info_1.png?v=1714033735'
                                alt='performance tip'
                                width={15}
                                height={15}
                              />
                            </div>
                          </div>

                          {tooltip === idx && (
                            <ProductPerformanceTips
                              name={title}
                              isTooltipOpen={isTooltipOpen}
                              handleCloseModal={handleCloseModal}
                            />
                          )}
                        </div>
                        <Progressbar bgcolor='#7ed321' progress={percentage} />
                      </div>
                    );
                  })}
                </div>
              )}

              {rankingMetaField[0] != undefined && (
                <div className='performance-chart-mobile'>
                  <PerformanceChart rankingMetaField={rankingMetaField} />
                </div>
              )}

              {metaObjects.length > 0 && (
                <div className='our-text'>
                  <h2 className='complete-setup-title'>UPGRADE YOUR RIG</h2>
                </div>
              )}
              {metaObjects.length > 0 ? (
                <div>
                  {Array.isArray(metaObjects) &&
                    metaObjects !== null &&
                    metaObjects.map((item) => {
                      if (item?.products !== 'indefined') {
                        return (
                          <NewProductCustomize
                            category={item?.title}
                            metaObject={item}
                            selectedUpsellProduct={selectedUpsellProduct}
                            handleSelectingUpsellProducts={
                              handleSelectingUpsellProducts
                            }
                            addToCart={addToCart}
                            selected={preInstallPrice}
                            handlePreInstallChange={handlePreInstallChange}
                            handlePreInstallResetChange={
                              handlePreInstallResetChange
                            }
                            handlePreSelected={handlePreSelected}
                            handleCustomDesignUrl={handleCustomDesignUrl}
                          />
                        );
                      }
                      return null;
                    })}
                </div>
              ) : (
                <div>
                  {/* {otherMetaFieldsFilter !== null &&
                    otherMetaFieldsFilter !== false &&
                    otherMetaFieldsFilter.length > 0 &&
                    otherMetaFieldsFilter &&
                    // metaObjects === null &&
                    otherMetaFieldsFilterNewOrder.map((itm, idx) => {
                      const productMetaData = JSON.parse(itm?.node?.value);
                      return (
                        itm?.node?.namespace !== 'variants' &&
                        itm?.node?.namespace !== 'configurator' && (
                          <ProductCustomize
                            selected={preInstallPrice}
                            setPreInstallPrice={setPreInstallPrice}
                            handlePreInstallChange={handlePreInstallChange}
                            handlePreSelected={handlePreSelected}
                            handlePreInstallResetChange={
                              handlePreInstallResetChange
                            }
                            namespace={itm?.node?.namespace}
                            productMetaFields={productMetaData}
                            handleCustomDesignUrl={handleCustomDesignUrl}
                          />
                        )
                      );
                    })} */}
                </div>
              )}

              <div className='our-text'>
                <h2 className='complete-setup-title'>COMPLETE YOUR SETUP</h2>
              </div>
              {setupMetaObjects.length > 0 ? (
                <div>
                  {Array.isArray(setupMetaObjects) &&
                    setupMetaObjects !== null &&
                    setupMetaObjects.map((item) => {
                      if (item?.products !== 'indefined') {
                        return (
                          <NewProductSpecification
                            handleMetaFiled={(name) => handleMetaFiled(name)}
                            productTags={productTags}
                            productMetaFields={item?.products}
                            image_url={item?.image.image.originalSrc}
                            handleUpsellChange={handleUpsellChange}
                            name={item?.title}
                            selected={upsellProductPrice}
                          />
                        );
                      }
                      return null;
                    })}
                </div>
              ) : (
                upsellProductMetaFields.map((itm, idx) => {
                  return (
                    <ProductSpecification
                      handleMetaFiled={(name) => handleMetaFiled(name)}
                      productTags={productTags}
                      productMetaFields={itm?.products}
                      image_url={itm?.image}
                      handleUpsellChange={handleUpsellChange}
                      name={itm?.category}
                      selected={upsellProductPrice}
                    />
                  );
                })
              )}
            </div>
          </div>
        </div>

        <div id='product-details-section' />
        <div className='product-details-container'>
          <div className='product-details-column'>
            <p className='column-title'>
              <b className='title-highlight-text'>ABOUT THE</b> PRODUCT
            </p>

            <div className='product-column-description'>
              {productDescription}
            </div>
          </div>

          <div className='product-details-column product-column-border-left'>
            <p className='column-title'>
              <b className='title-highlight-text'>PURCHASE WITH</b> CONFIDENCE
            </p>

            <p className='product-column-description'>
              At AFTERSHOCK PC, we carefully select all components that go into
              our builds. We take into consideration not only quality and
              reliability, but also CURRENT price-points and local support :
              meaning we only work with brands who don’t just deliver solid
              review results, but have also shown a track record for providing
              solid long term hardware support SPECFICALLY in Singapore. All
              Systems come backed by a 3-year AFTERSHOCK hardware warranty with
              lifetime free technical support – so you can ensure your
              investment is well protected.
            </p>

            <div className='purchase-column-image-container'>
              <img
                className='purchase-column-image'
                src={`https://cdn.shopify.com/s/files/1/0637/0407/2436/files/purchase-with-confidence.png?v=1714066803`}
                alt='purchase-with-confidence'
                width={416}
                height={246}
              />
            </div>
          </div>
        </div>

        {sameDayShipAvab == 'same-day-shipping' ? (
          <div className='product-details-container'>
            <div className='product-details-column'>
              <div div className='warranty-column-image'>
                <img
                  className='warranty-column-image'
                  src={`https://cdn.shopify.com/s/files/1/0637/0407/2436/files/warranty-image.png?v=1714066765`}
                  alt='warranty'
                  width={'100%'}
                  height={'auto'}
                />
              </div>
              <p className='column-title'>
                <b className='title-highlight-text'>INDUSTRY LEADING</b> 3 YEARS
                WARRANTY & LIFETIME CUSTOMER SUPPORT
              </p>
              <p className='product-column-description'>
                We’re the only system builders to give you the ultimate peace of
                mind with our free 3 Year Warranty, with carry-in, pick-up and
                extension options. We cover parts, labour and shipping costs to
                make sure your rig travels stress-free every step of the way.
                Skip the robots and wait times without having to deal with
                third-party service providers. We guarantee you will speak to
                real Aftershock PC experts who will never let you down with our
                lifetime customer support.
              </p>
            </div>

            <div
              className='product-details-column product-column-border-left'
              id='same-day-delivery-badge'
            >
              <div div className='warranty-column-image'>
                <img
                  className='warranty-column-image'
                  src='https://cdn.shopify.com/s/files/1/0637/0407/2436/files/sdd-product-details.png?v=1714064963'
                  alt='warranty'
                  width={'100%'}
                  height={'auto'}
                />
              </div>
              <p className='column-title'>
                <b className='title-highlight-text'>OPT FOR</b> SAME DAY
                DELIVERY
                <span>
                  ,
                  <b className='title-highlight-text'>
                    <br />
                    GET YOUR PC IN
                  </b>{' '}
                  24 HOURS
                </span>
              </p>
              <p className='product-column-description'>
                AFTERSHOCK PC typically delivers PCs between 8pm-11:30pm daily.
                For models marked with same day delivery, you can select same
                day delivery on checkout to receive delivery the same evening!
                (business days only, and for orders before 3PM daily. For orders
                after 3PM, delivery will next business day) Upon order, our team
                will be in contact to assist with delivery arrangements.
              </p>
            </div>
          </div>
        ) : (
          <div className='product-details-container'>
            <div className='product-details-column warranty-not-sdd'>
              <div div className='warranty-column-image'>
                <img
                  className='warranty-column-image'
                  src={
                    widthSize > 500
                      ? `https://cdn.shopify.com/s/files/1/0637/0407/2436/files/warranty-not-same-delivery.png?v=1714066841`
                      : `https://cdn.shopify.com/s/files/1/0637/0407/2436/files/warranty-image.png?v=1714066765`
                  }
                  alt='warranty'
                  width={'100%'}
                  height={'auto'}
                />
              </div>
              <p className='column-title' style={{ textAlign: 'center' }}>
                <b className='title-highlight-text'>INDUSTRY LEADING</b> 3 YEARS
                WARRANTY & LIFETIME CUSTOMER SUPPORT
              </p>
              <p
                className='product-column-description'
                style={{ textAlign: 'center' }}
              >
                We’re the only system builders to give you the ultimate peace of
                mind with our free 3 Year Warranty, with carry-in, pick-up and
                extension options. We cover parts, labour and shipping costs to
                make sure your rig travels stress-free every step of the way.
                Skip the robots and wait times without having to deal with
                third-party service providers. We guarantee you will speak to
                real Aftershock PC experts who will never let you down with our
                lifetime customer support.
              </p>
            </div>
          </div>
        )}
        <div className='product_footer'>
          <div className='wrapper'>
            <div className='product_add2cart cart__desktop'>
              <ProductAddToCart
                sameDayShipping={sameDayShipping}
                products={addToCart}
                productTitle={productTitle}
                productPrice={finalProductPrice}
                productVariant={productVariant}
                productCompareAtPrice={finalComparedProductPrice}
                productAvailable={productAvailable}
                productId={productId}
                customDesignUrl={customDesignUrl}
                freight={freight}
                deliveryDays={deliveryDays}
                collectionDays={collectionDays}
              />
            </div>
            <div className='product_add2cart cart__mobile'>
              <ProductAddCartMobile
                sameDayShipping={sameDayShipping}
                products={addToCart}
                productTitle={productTitle}
                productPrice={finalProductPrice}
                productVariant={productVariant}
                productCompareAtPrice={finalComparedProductPrice}
                productAvailable={productAvailable}
                productId={productId}
                deliveryDays={deliveryDays}
                collectionDays={collectionDays}
              />
            </div>
          </div>
        </div>
      </div>
      <style jsx>{styles}</style>
    </>
  );
};

Product.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  data: PropTypes.object.isRequired,
};

export default Product;
